class FastTrackLoader {
  constructor() {
    const cssId = 'fasttrack-crm-css'; // you could encode the css path itself to generate id..
    if (!document.getElementById(cssId)) {
      const head = document.getElementsByTagName('head')[0];
      const link = document.createElement('link');
      link.id = cssId;
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.href = `${process.env.FILE_LOCATION}/css/fasttrack-crm-app.css?v=${
        process.env.GIT_COMMITHASH
      }`;
      link.media = 'all';
      head.appendChild(link);

      window.fasttrackVersion = (process && process.env && process.env.GIT_COMMITHASH) ? process.env.GIT_COMMITHASH : 'local';
    }

    const appDiv = document.createElement('div');
    appDiv.id = 'fasttrack-crm';

    document.body.appendChild(appDiv);

    const scriptVendor = document.createElement('script');
    scriptVendor.async = true;
    scriptVendor.onload = function () { /* eslint-disable-line */
      console.log('Fasttrack CRM: Vendor JS loaded');
      const scriptMain = document.createElement('script');
      scriptMain.async = true;
      scriptMain.onload = function () { /* eslint-disable-line */
        console.log('Fasttrack CRM: Main script loaded');
      };
      scriptMain.src = `${
        process.env.FILE_LOCATION
      }/js/fasttrack-crm-app.js?v=${process.env.GIT_COMMITHASH}`;

      document.body.appendChild(scriptMain);
    };
    scriptVendor.src = `${
      process.env.FILE_LOCATION
    }/js/fasttrack-crm-chunk-vendors.js?v=${process.env.GIT_COMMITHASH}`;

    document.body.appendChild(scriptVendor);
  }
}

window.FastTrackLoader = FastTrackLoader;
